import * as d3 from 'd3'
import 'smiles-drawer'
var Kekule = require('kekule').Kekule;
export default {
  // transfer the list to a dict (str key to str value)
  list2dict: function (list, key, value) {
    let result = {}
    for (let i in list) {
      result[list[i][key]] = list[i][value]
    }
    return result
  },
  // transfer the list to a dict (str key to list value)
  list2dictList: function (list, key, value) {
    let result = {}
    for (let i in list) {
      if (list[i][key] in result) {
        result[list[i][key]].push(list[i][value])
      } else {
        result[list[i][key]] = [list[i][value]]
      }
    }
    for (let i in result) {
      result[i] = Array.from(new Set(result[i]))
    }
    return result
  },
  // transfer the list to a dict (str key to the row)
  list2dict2: function (list, key) {
    let result = {}
    for (let i in list) {
      result[list[i][key]] = list[i]
    }
    return result
  },
  test: function (row, column, event) {
    console.log("测试")
  },
  test2: function () {
    console.log("测试")
  },
  drawMsData (msList, selecter, Q1 = null, width = 1000, height = 300, transformX = 50, transformY = 0, xlab = "18px", ylab = "18px") {
    var msDict2 = []
    if (Q1) {
      msDict2.push({ "ms": Q1, "intensity": 30, "textcolor": 'rgba(255, 165, 0, 1)', "rectcolor": 'rgba(255, 165, 0, 0.8)', "mstext": "Q1_" + Q1 })
    }
    for (var i = 0; i < msList.length; i += 2) {
      if (msList[i + 1] >= 20) {
        msDict2.push({ "ms": msList[i], "intensity": msList[i + 1], "textcolor": 'rgba(0, 0, 139, 0.8)', "rectcolor": '#D95F02', "mstext": msList[i] })
      } else if (msList[i + 1] < 20 && msList[i + 1] > 0) {
        msDict2.push({ "ms": msList[i], "intensity": msList[i + 1], "textcolor": 'rgba(255, 255, 255, 0)', "rectcolor": '#D95F02', "mstext": msList[i] })
      }
    }
    var xlist = msDict2.map(x => x.ms)
    var ylist = msDict2.map(x => x.intensity)
    d3.select(selecter).select("svg").remove()
    var svg = d3.select(selecter)
      .append('svg').attr("width", width)
      .attr("height", height)
      .attr('transform', 'translate(' + transformX + ',' + transformY + ')')
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%");
    var g_x = 30
    var g_y = 20
    svg.append("clipPath")
      .attr("id", "clip")
      .append("rect")
      // .attr("x", 30)
      // .attr("y", 20)
      .attr('transform', 'translate(0,-15)')
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
    var g = svg
      .append('g')
      .attr("width", svg.attr("width") * 0.8)
      .attr("height", svg.attr("height") * 0.8)
      .attr('transform', 'translate(50,20)')
      .attr("clip-path", "url(#clip)")

    var plotWidth = g.attr("width")
    var plotHeight = g.attr("height")
    const zoom = d3.zoom()
      .scaleExtent([1, 32])
      .extent([[0, 0], [plotWidth, plotHeight]])
      .translateExtent([[0, 0], [plotWidth, plotHeight]])
      .on("zoom", zoomed);
    svg.call(zoom)
      .transition()
      .duration(750)
      .call(zoom.scaleTo, 1);

    function zoomed (event) {
      scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))
      g_text.attr("x", d => scaleLinearX(d.ms));
      g_rect.attr("x", d => scaleLinearX(d.ms));
      axisX = (g1, x) => g1
        .call(d3.axisBottom(x).ticks(Math.floor(event.transform.k * 10)))
      gx.call(axisX, scaleLinearX);
    }
    console.log("测试2")
    console.log(g.attr("width") * 0.8)
    console.log(g.attr("height") * 0.8)

    var scaleLinearX = d3.scaleLinear().domain([0, 1500]).range([0, g.attr("width")])
    var scaleLinearY = d3.scaleLinear().domain([100, 0]).range([0, g.attr("height")])
    var g_text = g.selectAll('text')
      .data(msDict2)
      .enter()
      .append('text')
      .attr('class', function (d) { return "ms_" + String(d.ms).replace('.', '_') })
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        return scaleLinearY(d.intensity);
      })
      .attr('fill', function (d, i) {
        return d.textcolor;
      })
      .text(function (d) {
        return d.mstext;
      })

    var g_rect = g.selectAll('rect')
      .data(msDict2)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        return scaleLinearY(d.intensity);
      })
      .attr('width', 3)
      .attr('height', function (d, i) {
        return g.attr("height") - scaleLinearY(d.intensity);
      })
      .attr('fill', function (d, i) {
        return d.rectcolor;
      })
      .on("mouseover", function (event, d) {
        d3.select('text.ms_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue")
        d3.select(this)
          .attr("fill", "steelblue");
      })
      .on("mouseout", function (event, d) {
        d3.select('text.ms_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", d.textcolor)
        d3.select(this)
          .transition()
          .duration(1500)
          .attr("fill", d.rectcolor);
      })

    var axisX = (g1, x) => g1
      .call(d3.axisBottom(x).ticks(10))//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
    var axisY = (g1, y) => g1
      .call(d3.axisLeft(y).ticks(5))

    var ytranslate = 20 + Number(g.attr("height"))
    svg.append("clipPath")
      .attr("id", "clipx")
      .append("rect")
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
      .attr('transform', 'translate(-5,0)')
    const gx = svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .attr('transform', 'translate(50,' + ytranslate + ')')
      .call(axisX, scaleLinearX)
      .attr("clip-path", "url(#clipx)")
      .style('font-size', xlab);
    svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .call(axisY, scaleLinearY)
      .attr('transform', 'translate(50,20)')
      .style('font-size', ylab);

  },
  drawMsData2 (msList, selecter, { Q1 = null, width = 1000, height = 300, transformX = 50, transformY = 0, xlab = "18px", ylab = "18px" } = {}) {
    var msDict2 = []
    console.log("测试去")
    if (Q1) {
      msDict2.push({ "ms": Q1, "intensity": 30, "textcolor": 'rgba(255, 165, 0, 1)', "rectcolor": 'rgba(255, 165, 0, 0.8)', "mstext": "Q1_" + Q1 })
    }
    for (var i = 0; i < msList.length; i += 2) {
      if (msList[i + 1] >= 20) {
        msDict2.push({ "ms": msList[i], "intensity": msList[i + 1], "textcolor": 'rgba(0, 0, 139, 0.8)', "rectcolor": '#D95F02', "mstext": msList[i] })
      } else if (msList[i + 1] < 20 && msList[i + 1] > 0) {
        msDict2.push({ "ms": msList[i], "intensity": msList[i + 1], "textcolor": 'rgba(255, 255, 255, 0)', "rectcolor": '#D95F02', "mstext": msList[i] })
      }
    }
    var xlist = msDict2.map(x => x.ms)
    var ylist = msDict2.map(x => x.intensity)
    d3.select(selecter).select("svg").remove()
    var svg = d3.select(selecter)
      .append('svg').attr("width", width)
      .attr("height", height)
      .attr('transform', 'translate(' + transformX + ',' + transformY + ')')
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%");
    var g_x = 30
    var g_y = 20
    svg.append("clipPath")
      .attr("id", "clip")
      .append("rect")
      // .attr("x", 30)
      // .attr("y", 20)
      .attr('transform', 'translate(0,-15)')
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
    var g = svg
      .append('g')
      .attr("width", svg.attr("width") * 0.8)
      .attr("height", svg.attr("height") * 0.8)
      .attr('transform', 'translate(50,20)')
      .attr("clip-path", "url(#clip)")

    var plotWidth = g.attr("width")
    var plotHeight = g.attr("height")
    const zoom = d3.zoom()
      .scaleExtent([1, 32])
      .extent([[0, 0], [plotWidth, plotHeight]])
      .translateExtent([[0, 0], [plotWidth, plotHeight]])
      .on("zoom", zoomed);
    svg.call(zoom)
      .transition()
      .duration(750)
      .call(zoom.scaleTo, 1);

    function zoomed (event) {
      scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))
      g_text.attr("x", d => scaleLinearX(d.ms));
      g_rect.attr("x", d => scaleLinearX(d.ms));
      axisX = (g1, x) => g1
        .call(d3.axisBottom(x).ticks(Math.floor(event.transform.k * 10)))
      gx.call(axisX, scaleLinearX);
    }
    console.log("测试2")
    console.log(g.attr("width") * 0.8)
    console.log(g.attr("height") * 0.8)

    var scaleLinearX = d3.scaleLinear().domain([0, 1500]).range([0, g.attr("width")])
    var scaleLinearY = d3.scaleLinear().domain([100, 0]).range([0, g.attr("height")])
    var g_text = g.selectAll('text')
      .data(msDict2)
      .enter()
      .append('text')
      .attr('class', function (d) { return "ms_" + String(d.ms).replace('.', '_') })
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        return scaleLinearY(d.intensity);
      })
      .attr('fill', function (d, i) {
        return d.textcolor;
      })
      .text(function (d) {
        return d.mstext;
      })

    var g_rect = g.selectAll('rect')
      .data(msDict2)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        return scaleLinearY(d.intensity);
      })
      .attr('width', 3)
      .attr('height', function (d, i) {
        return g.attr("height") - scaleLinearY(d.intensity);
      })
      .attr('fill', function (d, i) {
        return d.rectcolor;
      })
      .on("mouseover", function (event, d) {
        d3.select('text.ms_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue")
        d3.select(this)
          .attr("fill", "steelblue");
      })
      .on("mouseout", function (event, d) {
        d3.select('text.ms_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", d.textcolor)
        d3.select(this)
          .transition()
          .duration(1500)
          .attr("fill", d.rectcolor);
      })

    var axisX = (g1, x) => g1
      .call(d3.axisBottom(x).ticks(10))//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
    var axisY = (g1, y) => g1
      .call(d3.axisLeft(y).ticks(5))

    var ytranslate = 20 + Number(g.attr("height"))
    svg.append("clipPath")
      .attr("id", "clipx")
      .append("rect")
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
      .attr('transform', 'translate(-5,0)')
    const gx = svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .attr('transform', 'translate(50,' + ytranslate + ')')
      .call(axisX, scaleLinearX)
      .attr("clip-path", "url(#clipx)")
      .style('font-size', xlab);
    svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .call(axisY, scaleLinearY)
      .attr('transform', 'translate(50,20)')
      .style('font-size', ylab);

  },
  drawMsDataT (msList, selecter, width = 1000, height = 300, transformX = 100, transformY = 0) {
    var msDict2 = []
    for (var i = 0; i < msList.length; i += 2) {
      if (msList[i + 1] > 0) {
        msDict2.push({ "ms": msList[i], "intensity": msList[i + 1] })
      }
    }
    var xlist = msDict2.map(x => x.ms)
    var ylist = msDict2.map(x => x.intensity)
    d3.select(selecter).select("svg").remove()
    var svg = d3.select(selecter).append('svg').attr("width", width).attr("height", height).attr('transform', 'translate(' + transformX + ',' + transformY + ')')
    var g_x = 30
    var g_y = 20
    svg.append("clipPath")
      .attr("id", "clip")
      .append("rect")
      // .attr("x", 30)
      // .attr("y", 20)
      .attr('transform', 'translate(0,-15)')
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
    var g = svg
      .append('g')
      .attr("width", svg.attr("width") * 0.8)
      .attr("height", svg.attr("height") * 0.8)
      .attr('transform', 'translate(30,20)')
      .attr("clip-path", "url(#clip)")
    var plotWidth = g.attr("width")
    var plotHeight = g.attr("height")
    const zoom = d3.zoom()
      .scaleExtent([1, 32])
      .extent([[0, 0], [plotWidth, plotHeight]])
      .translateExtent([[0, 0], [plotWidth, plotHeight]])
      .on("zoom", zoomed);
    svg.call(zoom)
      .transition()
      .duration(750)
      .call(zoom.scaleTo, 1);

    function zoomed (event) {
      scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))
      g_text.attr("x", d => scaleLinearX(d.ms));
      g_rect.attr("x", d => scaleLinearX(d.ms));
      axisX = (g1, x) => g1
        .call(d3.axisBottom(x).ticks(Math.floor(event.transform.k * 10)))
      gx.call(axisX, scaleLinearX);
    }
    console.log("测试2")
    console.log(g.attr("width") * 0.8)
    console.log(g.attr("height") * 0.8)

    var scaleLinearX = d3.scaleLinear().domain([0, 1500]).range([0, g.attr("width")])
    var scaleLinearY = d3.scaleLinear().domain([100, 0]).range([0, g.attr("height")])
    var g_text = g.selectAll('text')
      .data(msDict2)
      .enter()
      .append('text')
      .attr('class', function (d) { return "ms_" + String(d.ms).replace('.', '_') })
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        return scaleLinearY(d.intensity);
      })
      .attr('fill', 'rgba(0, 0, 139, 0.8)')
      .text(function (d) {
        return d.ms;
      })

    var g_rect = g.selectAll('rect')
      .data(msDict2)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        return scaleLinearY(d.intensity);
      })
      .attr('width', 3)
      .attr('height', function (d, i) {
        return g.attr("height") - scaleLinearY(d.intensity);
      })
      .attr('fill', '#D95F02')
      .on("mouseover", function (event, d) {
        d3.select('text.ms_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue")
        d3.select(this)
          .attr("fill", "steelblue");
      })
      .on("mouseout", function (event, d) {
        d3.select('text.ms_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.8)")
        d3.select(this)
          .transition()
          .duration(1500)
          .attr("fill", "#D95F02");
      })

    var axisX = (g1, x) => g1
      .call(d3.axisBottom(x).ticks(10))//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
    var axisY = (g1, y) => g1
      .call(d3.axisLeft(y).ticks(5))
    svg.append("clipPath")
      .attr("id", "clipx")
      .append("rect")
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
      .attr('transform', 'translate(-2,0)')
    var ytranslate = 20 + Number(g.attr("height"))
    const gx = svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .attr('transform', 'translate(30,' + ytranslate + ')')
      .call(axisX, scaleLinearX)
      .attr("clip-path", "url(#clipx)")
    svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .call(axisY, scaleLinearY)
      .attr('transform', 'translate(30,20)')
  },
  drawMsAlign (msList1, msList2, selecter, msList1Q1 = null, msList2Q1 = null, width = 1000, height = 300, transformX = 50, transformY = 0, xlab = "18px", ylab = "18px") {
    console.log("msDict2")
    var msDict2 = []
    if (msList1Q1 && msList2Q1) {
      msDict2.push({ "ms": msList1Q1, "intensity": 30, "type": "feature", "textcolor": 'rgba(255, 165, 0, 1)', "rectcolor": 'rgba(255, 165, 0, 0.8)', "mstext": "Q1_" + msList1Q1, "class": "Q1" })
      msDict2.push({ "ms": msList2Q1, "intensity": 30, "type": "library", "textcolor": 'rgba(255, 165, 0, 1)', "rectcolor": 'rgba(255, 165, 0, 0.8)', "mstext": "Q1_" + msList2Q1, "class": "Q1" })
    }
    console.log(msDict2)
    for (var i = 0; i < msList1.length; i += 2) {
      if (msList1[i + 1] >= 20) {
        msDict2.push({ "ms": msList1[i], "intensity": msList1[i + 1], "type": "feature", "textcolor": 'rgba(0, 0, 139, 0.8)', "rectcolor": '#D95F02', "mstext": msList1[i], "class": "Qn" })
      } else if (msList1[i + 1] < 20 && msList1[i + 1] > 0) {
        msDict2.push({ "ms": msList1[i], "intensity": msList1[i + 1], "type": "feature", "textcolor": 'rgba(255, 255, 255, 0)', "rectcolor": '#D95F02', "mstext": msList1[i], "class": "Qn" })
      }
    }
    for (var i = 0; i < msList2.length; i += 2) {
      if (msList2[i + 1] >= 20) {
        msDict2.push({ "ms": msList2[i], "intensity": msList2[i + 1], "type": "library", "textcolor": 'rgba(0, 0, 139, 0.8)', "rectcolor": '#D95F02', "mstext": msList2[i], "class": "Qn" })
      } else if (msList2[i + 1] < 20 && msList2[i + 1] > 0) {
        msDict2.push({ "ms": msList2[i], "intensity": msList2[i + 1], "type": "library", "textcolor": 'rgba(255, 255, 255, 0)', "rectcolor": '#D95F02', "mstext": msList2[i], "class": "Qn" })
      }
    }
    d3.select(selecter).select("svg").remove()
    var svg = d3.select(selecter)
      .append('svg').attr("width", width)
      .attr("height", height)
      .attr('transform', 'translate(' + transformX + ',' + transformY + ')')
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%");
    var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.8).attr('transform', 'translate(30,20)')
    var xlist = msDict2.map(x => x.ms)
    var ylist = msDict2.map(x => x.intensity)
    let scaleLinearX = d3.scaleLinear().domain([0, 1500]).range([0, g.attr("width")])
    let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), -d3.max(ylist)]).range([0, g.attr("height")])
    svg.append("clipPath")
      .attr("id", "clip")
      .append("rect")
      // .attr("x", 30)
      // .attr("y", 20)
      .attr('transform', 'translate(0,-15)')
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
    var g = svg
      .append('g')
      .attr("width", svg.attr("width") * 0.8)
      .attr("height", svg.attr("height") * 0.8)
      .attr('transform', 'translate(50,20)')
      .attr("clip-path", "url(#clip)")
    var plotWidth = g.attr("width")
    var plotHeight = g.attr("height")
    const zoom = d3.zoom()
      .scaleExtent([1, 32])
      .extent([[0, 0], [plotWidth, plotHeight]])
      .translateExtent([[0, 0], [plotWidth, plotHeight]])
      .on("zoom", zoomed);
    svg.call(zoom)
      .transition()
      .duration(750)
      .call(zoom.scaleTo, 1);

    function zoomed (event) {
      scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))
      g_text.attr("x", d => scaleLinearX(d.ms));
      g_rect.attr("x", d => scaleLinearX(d.ms));
      axisX = (g1, x) => g1
        .call(d3.axisBottom(x).ticks(Math.floor(event.transform.k * 10)))
      gx.call(axisX, scaleLinearX);
    }
    var g_text = g.selectAll('text')
      .data(msDict2)
      .enter()
      .append('text')
      .attr('class', function (d) {
        if (d.type == "feature") {
          return "ms_feature_" + d.class + "_" + String(d.ms).replace('.', '_');
        } else if (d.type == "library") {
          return "ms_library_" + d.class + "_" + String(d.ms).replace('.', '_');
        };
      })
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        if (d.type == "feature") {
          return scaleLinearY(d.intensity);
        } else if (d.type == "library") {
          return scaleLinearY(-d.intensity - 10);
        }
      })
      .attr('fill', function (d, i) {
        return d.textcolor
      })
      .text(function (d) {
        return d.mstext;
      })

    var g_rect = g.selectAll('rect')
      .data(msDict2)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        if (d.type == "feature") {
          return scaleLinearY(d.intensity);
        } else if (d.type == "library") {
          return scaleLinearY(0);
        };
      })
      .attr('width', 3)
      .attr('height', function (d, i) {
        return (g.attr("height") / 2) - scaleLinearY(d.intensity);
      })
      .attr('fill', function (d, i) {
        return d.rectcolor
      })
      .on("mouseover", function (event, d) {
        if (d.type == "feature") {
          d3.select('text.ms_feature_' + d.class + "_" + String(d.ms).replace('.', '_')).attr("fill", "steelblue");
        } else if (d.type == "library") {
          d3.select('text.ms_library_' + d.class + "_" + String(d.ms).replace('.', '_')).attr("fill", "steelblue");
        };
        d3.select(this)
          .attr("fill", "steelblue");
      })
      // .delay(2000)
      .on("mouseout", function (event, d) {
        if (d.type == "feature") {
          d3.select('text.ms_feature_' + d.class + "_" + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", d.textcolor);
        } else if (d.type == "library") {
          d3.select('text.ms_library_' + d.class + "_" + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", d.textcolor);
        };
        d3.select(this)
          .transition()
          .duration(1500)
          .attr("fill", d.rectcolor);
      })
    var axisX = (g1, x) => g1
      .call(d3.axisBottom(x).ticks(10))//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
    var axisY = (g1, y) => g1
      .call(d3.axisLeft(y).ticks(5))
    svg.append("clipPath")
      .attr("id", "clipx")
      .append("rect")
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
      .attr('transform', 'translate(-2,0)')
    var ytranslate = 20 + Number(g.attr("height")) / 2
    const gx = svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .attr('transform', 'translate(50,' + ytranslate + ')')
      .call(axisX, scaleLinearX)
      .attr("clip-path", "url(#clipx)")
      .style('font-size', xlab);
    svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .call(axisY, scaleLinearY)
      .attr('transform', 'translate(50,20)')
      .style('font-size', ylab);
  },
  drawMsAlignT (msList1, msList2, selecter, width = 1000, height = 300, transformX = 100, transformY = 0) {
    var msDict2 = []
    for (var i = 0; i < msList1.length; i += 2) {
      msDict2.push({ "ms": msList1[i], "intensity": msList1[i + 1], "type": "feature" })
    }
    for (var i = 0; i < msList2.length; i += 2) {
      msDict2.push({ "ms": msList2[i], "intensity": msList2[i + 1], "type": "library" })
    }
    d3.select(selecter).select("svg").remove()
    var svg = d3.select(selecter).append('svg').attr("width", width).attr("height", height).attr('transform', 'translate(' + transformX + ',' + transformY + ')')
    var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.8).attr('transform', 'translate(30,20)')
    var xlist = msDict2.map(x => x.ms)
    var ylist = msDict2.map(x => x.intensity)
    let scaleLinearX = d3.scaleLinear().domain([0, 1500]).range([0, g.attr("width")])
    let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), -d3.max(ylist)]).range([0, g.attr("height")])
    svg.append("clipPath")
      .attr("id", "clip")
      .append("rect")
      // .attr("x", 30)
      // .attr("y", 20)
      .attr('transform', 'translate(0,-15)')
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
    var g = svg
      .append('g')
      .attr("width", svg.attr("width") * 0.8)
      .attr("height", svg.attr("height") * 0.8)
      .attr('transform', 'translate(30,20)')
      .attr("clip-path", "url(#clip)")
    var plotWidth = g.attr("width")
    var plotHeight = g.attr("height")
    const zoom = d3.zoom()
      .scaleExtent([1, 32])
      .extent([[0, 0], [plotWidth, plotHeight]])
      .translateExtent([[0, 0], [plotWidth, plotHeight]])
      .on("zoom", zoomed);
    svg.call(zoom)
      .transition()
      .duration(750)
      .call(zoom.scaleTo, 1);

    function zoomed (event) {
      scaleLinearX.range([0, plotWidth].map(d => event.transform.applyX(d)))
      g_text.attr("x", d => scaleLinearX(d.ms));
      g_rect.attr("x", d => scaleLinearX(d.ms));
      axisX = (g1, x) => g1
        .call(d3.axisBottom(x).ticks(Math.floor(event.transform.k * 10)))
      gx.call(axisX, scaleLinearX);
    }
    var g_text = g.selectAll('text')
      .data(msDict2)
      .enter()
      .append('text')
      .attr('class', function (d) {
        if (d.type == "feature") {
          return "ms_feature_" + String(d.ms).replace('.', '_');
        } else if (d.type == "library") {
          return "ms_library_" + String(d.ms).replace('.', '_');
        };
      })
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        if (d.type == "feature") {
          return scaleLinearY(d.intensity);
        } else if (d.type == "library") {
          return scaleLinearY(-d.intensity - 10);
        }
      })
      .attr('fill', 'rgba(0, 0, 139, 0.8)')
      .text(function (d) {
        return d.ms;
      })

    var g_rect = g.selectAll('rect')
      .data(msDict2)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        return scaleLinearX(d.ms)
      })
      .attr('y', function (d, i) {
        if (d.type == "feature") {
          return scaleLinearY(d.intensity);
        } else if (d.type == "library") {
          return scaleLinearY(0);
        };
      })
      .attr('width', 3)
      .attr('height', function (d, i) {
        return (g.attr("height") / 2) - scaleLinearY(d.intensity);
      })
      .attr('fill', '#D95F02')
      .on("mouseover", function (event, d) {
        if (d.type == "feature") {
          d3.select('text.ms_feature_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue");
        } else if (d.type == "library") {
          d3.select('text.ms_library_' + String(d.ms).replace('.', '_')).attr("fill", "steelblue");
        };
        d3.select(this)
          .attr("fill", "steelblue");
      })
      // .delay(2000)
      .on("mouseout", function (event, d) {
        if (d.type == "feature") {
          d3.select('text.ms_feature_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.8)");
        } else if (d.type == "library") {
          d3.select('text.ms_library_' + String(d.ms).replace('.', '_')).transition().duration(1500).attr("fill", "rgba(0, 0, 139, 0.8)");
        };
        d3.select(this)
          .transition()
          .duration(1500)
          .attr("fill", "#D95F02");
      })
    var axisX = (g1, x) => g1
      .call(d3.axisBottom(x).ticks(10))//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
    var axisY = (g1, y) => g1
      .call(d3.axisLeft(y).ticks(5))
    svg.append("clipPath")
      .attr("id", "clipx")
      .append("rect")
      .attr("width", svg.attr("width") * 0.95)
      .attr("height", svg.attr("height"))
      .attr('transform', 'translate(-2,0)')
    var ytranslate = 20 + Number(g.attr("height")) / 2
    const gx = svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .attr('transform', 'translate(30,' + ytranslate + ')')
      .call(axisX, scaleLinearX)
      .attr("clip-path", "url(#clipx)")
    svg.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .call(axisY, scaleLinearY)
      .attr('transform', 'translate(30,20)')
  },
  // Combines the code from icicle and tree examples
  // Copyright 2022 John Alexis Guerra Gómez
  // Copyright 2021 Observable, Inc.
  // Released under the ISC license.
  // https://observablehq.com/@john-guerra/tree-value

  TreeValue (
    data,
    {
      // data is either tabular (array of objects) or hierarchy (nested objects)
      path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
      id = Array.isArray(data) ? (d) => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
      parentId = Array.isArray(data) ? (d) => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
      children, // if hierarchical data, given a d in data, returns its children
      format = ",", // format specifier string or function for values
      value, // given a node d, returns a quantitative value (for area encoding; null for count)
      sort = (a, b) => d3.descending(a.value, b.value), // how to sort nodes prior to layout
      label, // given a node d, returns the name to display on the rectangle
      title, // given a node d, returns its hover text
      link, // given a node d, its link (if any)
      linkTarget = "_blank", // the target attribute for links (if any)
      width = 640, // outer width, in pixels
      height = 400, // outer height, in pixels
      margin = 0, // shorthand for margins
      marginTop = margin, // top margin, in pixels
      marginRight = margin, // right margin, in pixels
      marginBottom = margin, // bottom margin, in pixels
      marginLeft = margin, // left margin, in pixels
      padding = 1, // cell padding, in pixels
      round = false, // whether to round to exact pixels
      color = d3.interpolateSpectral, // color scheme, if any
      fill = "#ccc", // fill for node rects (if no color encoding)
      fillOpacity = 1, // fill opacity for node rects
      stroke = "#555", // stroke for links
      strokeWidth = 1.5, // stroke width for links
      strokeOpacity = 0.4, // stroke opacity for links
      strokeLinejoin, // stroke line join for links
      strokeLinecap, // stroke line cap for links
      halo = "#fff", // color of label halo
      haloWidth = 3, // padding around the labels
      parentR = 3 // Radius for inner nodes
    } = {}
  ) {
    // If id and parentId options are specified, or the path option, use d3.stratify
    // to convert tabular data to a hierarchy; otherwise we assume that the data is
    // specified as an object {children} with nested objects (a.k.a. the “flare.json”
    // format), and use d3.hierarchy.
    const root =
      path != null
        ? d3.stratify().path(path)(data)
        : id != null || parentId != null
          ? d3.stratify().id(id).parentId(parentId)(data)
          : d3.hierarchy(data, children);

    // Compute the values of internal nodes by aggregating from the leaves.
    value == null ? root.count() : root.sum((d) => Math.max(0, value(d)));

    // Compute formats.
    if (typeof format !== "function") format = d3.format(format);

    // Sort the leaves (typically by descending value for a pleasing layout).
    if (sort != null) root.sort(sort);

    // Compute the partition layout. Note that x and y are swapped!
    d3
      .partition()
      .size([height - marginTop - marginBottom, width - marginLeft - marginRight])
      .padding(padding)
      .round(round)(root);

    // Construct a color scale.
    if (color != null) {
      color = d3
        .scaleSequential([0, root.children.length - 1], color)
        .unknown(fill);
      root.children.forEach((child, i) => (child.index = i));
    }

    const descendants = root.descendants();

    // Radius scale
    let rScale = d3
      .scaleLinear()
      .domain([0, d3.max(descendants, (d) => d?.value)])
      .range([0, d3.max(descendants, (d) => (d.x1 - d.x0) / 2)]);

    const svg = d3
      .create("svg")
      .attr("viewBox", [-marginLeft, -marginTop, width, height])
      .attr("width", width)
      .attr("height", height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10);

    // Links
    svg
      .append("g")
      .attr("fill", "none")
      .attr("stroke", stroke)
      .attr("stroke-opacity", strokeOpacity)
      .attr("stroke-linecap", strokeLinecap)
      .attr("stroke-linejoin", strokeLinejoin)
      .attr("stroke-width", strokeWidth)
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr(
        "d",
        d3
          .linkHorizontal()
          .x((d) => d.y0 + (d.y1 - d.y0) / 2)
          .y((d) => d.x0 + (d.x1 - d.x0) / 2)
      );

    const cell = svg
      .append("g")
      .selectAll("a")
      .data(descendants)
      .join("a")
      .attr("xlink:href", link == null ? null : (d) => link(d.data, d))
      .attr("target", link == null ? null : linkTarget)
      .attr(
        "transform",
        (d) => `translate(${d.y0 + +(d.y1 - d.y0) / 2},${d.x0})`
      );

    cell
      .append("circle")
      .attr("r", (d) => (!d.children ? rScale(d.value) : parentR))
      .attr("cy", (d) => rScale(d.value))
      .attr("fill", (d) =>
        !d.children
          ? color
            ? color(d.ancestors().reverse()[1]?.index)
            : fill
          : "none"
      )
      .attr("stroke", (d) =>
        d.children
          ? color
            ? color(d.ancestors().reverse()[1]?.index)
            : fill
          : "none"
      )
      .attr("fill-opacity", fillOpacity);

    const text = cell
      .filter((d) => d.x1 - d.x0 > 10)
      .append("text")
      .attr("text-anchor", (d) => (d.children ? "end" : "start"))
      .attr("x", (d) => (!d.children ? rScale(d.value) + 2 : -parentR - 2))
      .attr("y", (d) => (d.x1 - d.x0) / 2)
      .attr("paint-order", "stroke")
      .attr("stroke", halo)
      .attr("stroke-width", haloWidth)
      .attr("dy", "0.32em");

    if (label != null) text.append("tspan").text((d) => label(d.data, d));

    text
      .append("tspan")
      .attr("fill-opacity", 0.7)
      .attr("dx", label == null ? null : 3)
      .text((d) => format(d.value));

    if (title != null) cell.append("title").text((d) => title(d.data, d));

    return svg.node();
  },
  getStructure (smiles, id, {
    width = 150,
    height = 150
  } = {}) {
    let options = {
      width: width,
      height: height
    }
    let smilesDrawer = new SmilesDrawer.Drawer(options);
    // smilesDrawer.draw('C1CCCCC1', 'draw2d')
    SmilesDrawer.parse(smiles, function (tree) {
      smilesDrawer.draw(tree, id, 'light', false);
    }, function (err) {
      console.log(err);
    })
  },
  drawMol (molString, id, {
    width = null,
    height = null
  } = {}) {
    var mol = Kekule.IO.loadFormatData(molString, 'mol')
    var renderType = Kekule.Render.RendererType.R2D
    var parentElem = document.getElementById(id)
    Kekule.DomUtils.clearChildContent(parentElem)
    var painter = new Kekule.Render.ChemObjPainter(renderType, mol)

    if (!width || !height) {
      var dim = Kekule.HtmlElementUtils.getElemOffsetDimension(parentElem)
      width = dim.width
      height = dim.height
    }
    var context = painter.createContext(parentElem, width, height)
    var options = {
      useAtomSpecifiedColor: true,
      // atomColor: '#000000',
      bondColor: '#000000',
      autofit: true,
      // defBondLength: 30
    }
    painter.draw(context, { 'x': width / 2, 'y': height / 2 }, options)
  },
  downloadFile (content, fileName) {
    var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    var a = document.createElement("a");
    var url = window.URL.createObjectURL(blob);
    var filename = fileName;
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url)
  },
  drawGwas ({ gwasData = "gwasData", chomosomeData = "chomosomeData", gwasID = "gwasPlot", plot_width = 1000, plot_height = 400 } = {}) {
    console.log("chomosomeData")
    console.log(chomosomeData)
    let pointColor = {}
    for (let i = 0; i < 21; i++) {
      if (i % 3 == 0) {
        // pointColor[String(i + 1)] = "#E16A86"
        // pointColor[String(i + 1)] = "#d2e289"
        pointColor[String(i + 1)] = "#B2966C"
      } else if (i % 3 == 1) {
        // pointColor[String(i + 1)] = "#B88A00"
        // pointColor[String(i + 1)] = "#e4c084"
        pointColor[String(i + 1)] = "#6CB273"
      } else if (i % 3 == 2) {
        // pointColor[String(i + 1)] = "#50A315"
        // pointColor[String(i + 1)] = "#b2cba4"
        pointColor[String(i + 1)] = "#6C88B2"
      }
    }
    gwasID = "#" + gwasID
    console.log("gwasData")
    console.log(gwasData)
    console.log("chomosomeData")
    console.log(chomosomeData)
    console.log("gwasID")
    console.log(gwasID)
    console.log("plot_width")
    console.log(plot_width)
    console.log("plot_height")
    console.log(plot_height)
    d3.select(gwasID).select("svg").remove()
    var svg = d3.select(gwasID)
      .append('svg')
      .attr("width", plot_width)
      .attr("height", plot_height)
      .attr('transform', 'translate(20,0)')
      .attr("viewBox", [0, 0, plot_width, plot_height])
      .attr("style", "max-width: 100%");
    console.log("plot_height")
    console.log(plot_height)
    var g = svg.append('g').attr("width", svg.attr("width") * 0.8).attr("height", svg.attr("height") * 0.8).attr('transform', 'translate(80,20)')
    var xlist = chomosomeData.map(x => x.position)
    var ylist = gwasData.map(x => x.pvalue2)
    let scaleLinearX = d3.scaleLinear().domain([0, d3.max(xlist)]).range([0, g.attr("width")])
    let scaleLinearY = d3.scaleLinear().domain([d3.max(ylist), d3.min(ylist)]).range([0, g.attr("height")])
    console.log("hah")
    var ylab = 0.6 * plot_height + 10
    console.log("ylab")
    console.log(ylab)
    g.selectAll('circle')
      .data(gwasData)
      .enter()
      .append('circle')
      .attr('cx', function (d, i) {
        return scaleLinearX(d.position2)
      })
      .attr('cy', function (d, i) {
        return scaleLinearY(d.pvalue2);
      })
      .attr("r", 5)
      .attr('fill', function (d, i) {
        return pointColor[d.chromosome];
      })
      .style("cursor", "pointer")
      .on("mouseover", function (event, d) {
        d3.select(this)
          .attr("fill", "blue");
        console.log(event)
        // console.log(event.layerY)
        // console.log(event.x)
        // console.log(event.y)
        // return tooltip.html("<li>snp: " + d.snp + "</li><li>position: " + d.position + "</li>").attr("transform", "translate(" + event.offsetX + "," + event.offsetY + ")")
        var text = tooltip.selectAll("text")
          .data(["snp: " + d.snp, "position: " + d.position])
          .join("text")
          .attr("x", 0)
          .attr("y", (_, i) => `${i * 1.1}em`)
          .text(dd => dd)
        let xTemp = event.offsetX + 10
        text.attr("transform", "translate(" + xTemp + "," + event.offsetY + ")")
        // return tooltip.style('visibility', 'visible').text("snp: " + d.snp + "; position: " + d.position).attr("transform", "translate(" + event.offsetX + "," + event.offsetY + ")")
      })
      // .delay(2000)
      .on("mouseout", function (event, d) {
        d3.select(this)
          .transition()
          .duration(500)
          .attr("fill", pointColor[d.chromosome]);
        // return tooltip.style('visibility', 'hidden')
      })
    let tickValues = chomosomeData.map(x => x.position - x.length / 2)
    let tickLabels = chomosomeData.map(x => x.label)
    var axisX = d3.axisBottom(scaleLinearX).tickValues(tickValues).tickFormat((d, i) => tickLabels[i]);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
    var axisY = d3.axisLeft(scaleLinearY).ticks(5)
    // let tooltip = g.append('text')
    //   .attr('x', 0)
    //   .attr('y', 0)
    //   .style('fill', '#e56b07')
    //   .style('visibility', 'hidden')   // 是否可见（一开始设置为隐藏）
    //   .style('font-size', '18px')
    //   .style('font-weight', 'bold')
    var tooltip = svg.append("g")
      .attr('x', 0)
      .attr('y', 0)
    // .style('visibility', 'hidden')   // 是否可见（一开始设置为隐藏）
    g.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .attr('transform', 'translate(0,' + g.attr("height") + ')')
      .call(axisX)
      .style('font-size', '18px')
      .attr('width', 2)
    g.append('g')//在原有分g矩形分组里 再加一个x轴分组
      .call(axisY)
      .style('font-size', '18px')
      .attr('width', 2)
    var ylab = svg.append('text')
      .attr('transform', 'translate(20,' + ylab + ') rotate(270)')
    ylab.selectAll('tspan')
      .data(["-log", " 10 ", "p-value"])
      .enter()
      .append('tspan')
      .text(dd => dd)
      .style('font-size', (_, i) => [24, 12, 24][i] + "px")
      .style("font-style", (_, i) => ["normal", "normal", "italic"][i])

    g.selectAll('rect')
      .data(chomosomeData)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        return scaleLinearX(d.position)
      })
      .attr('y', 0)
      .attr('width', 1)
      .attr('height', scaleLinearY(d3.min(ylist)))
      .attr('fill', "rgba(0, 0, 139, 0.2)")
  },
  drawFrequencyHistogram (data, selectKey, id, {
    width = 400,
    height = 400,
    marginTop = 40,
    marginRight = 20,
    marginBottom = 60,
    marginLeft = 60,
  } = {}) {
    var bins = d3.bin()
      .thresholds(40)
      .value((d) => d[selectKey])
      (data);
    // Declare the x (horizontal position) scale.
    var x = d3.scaleLinear()
      .domain([bins[0].x0, bins[bins.length - 1].x1])
      .range([marginLeft, width - marginRight]);

    // Declare the y (vertical position) scale.
    var y = d3.scaleLinear()
      .domain([0, d3.max(bins, (d) => d.length)])
      .range([height - marginBottom, marginTop]);
    d3.select(id).select("svg").remove()
    var svg = d3.select(id)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto;")
    svg.append("g")
      .attr("fill", "steelblue")
      .selectAll()
      .data(bins)
      .join("rect")
      .attr("x", (d) => x(d.x0) + 1)
      .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
      .attr("y", (d) => y(d.length))
      .attr("height", (d) => y(0) - y(d.length))

    // Add the x-axis and label.
    svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))
      .call((g) => g.append("text")
        .attr("x", width / 2 + 20)
        .attr("y", marginBottom / 2 + 15)
        .attr("fill", "currentColor")
        .attr("text-anchor", "center")
        .text("Relative Content"))
      .style('font-size', '18px')

    // Add the y-axis and label, and remove the domain line.
    svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y).ticks(height / 40))
      // .call((g) => g.select(".domain").remove())
      .call((g) => g.append("text")
        .attr('transform', 'rotate(270)')
        .attr("x", -(height - marginTop - marginBottom) / 2)
        .attr("y", -marginLeft + 15)
        .attr("fill", "currentColor")
        .attr("text-anchor", "center")
        .text("Frequency")
      )
      .style('font-size', '18px')
  },
  drawHistogram (metaboliteList) {
    d3.select("#metaboliteHistogram").select("svg").remove()
    if (metaboliteList.length > 0) {
      var svg = d3.select("#metaboliteHistogram").append('svg')
        .attr("width", window.screen.width * 0.8)
        .attr("height", 800)
        .attr("viewBox", [0, 0, window.screen.width * 0.8, 800])
        .attr('transform', 'translate(50,0)')
        .attr("style", "max-width: 100%");
      var g = svg.append('g')
        .attr("width", svg.attr("width") * 0.8)
        .attr("height", svg.attr("height") * 0.4)
        .attr('transform', 'translate(100,20)')
      var plotWidth = g.attr("width")
      var plotHeight = g.attr("height")
      var xlist = [...Array(metaboliteList.length + 1).keys()].slice(1)
      var plotData = []
      for (let i = 0; i < xlist.length; i += 1) {
        plotData.push({ "x": xlist[i], "y": metaboliteList[i].q1I, "label": metaboliteList[i].featureID })
      }
      let scaleLinearX = d3.scaleLinear().domain([0, d3.max(xlist)]).range([0, g.attr("width")])
      let scaleLinearY = d3.scaleLinear().domain([d3.max(metaboliteList.map(d => d.q1I)), 0]).range([0, g.attr("height")])
      var g_rect = g.selectAll('rect')
        .data(plotData)
        .enter()
        .append('rect')
        .attr('x', function (d, i) {
          return scaleLinearX(d.x - 0.25)
        })
        .attr('y', function (d, i) {
          return scaleLinearY(d.y)
        })
        .attr('width', scaleLinearX(d3.max(xlist)) / xlist.length * 0.5)
        .attr('height', function (d, i) {
          return g.attr("height") - scaleLinearY(d.y);
        })
        // .attr('fill', "rgba(0, 0, 139, 0.2)")
        .attr('fill', "#6C6CB2")
      var g_text = g.selectAll('text')
        .data(plotData)
        .enter()
        .append('text')
        .attr('x', function (d, i) {
          return scaleLinearX(d.x)
        })
        .attr('y', function (d, i) {
          return scaleLinearX(d.y)
        })
        .attr('fill', 'rgba(0, 0, 139, 0.2)')
      var axisX = d3.axisBottom(scaleLinearX).tickValues(xlist).tickFormat((d, i) => plotData[i].label);//ticks 用来设置刻度间隔    其实就是把data数据 根据咱们的参数20  重新调整一下
      var axisY = d3.axisLeft(scaleLinearY).ticks(5).tickFormat(d3.format(".1e"))
      var gx = g.append('g')//在原有分g矩形分组里 再加一个x轴分组
        .attr('transform', 'translate(0,' + g.attr("height") + ')')
        .call(axisX)
        .selectAll("text")
        .style("text-anchor", "start")
        .attr("transform", "rotate(45 -10 10)")
        .style('font-size', '16px')
      g.append('g')//在原有分g矩形分组里 再加一个x轴分组
        .call(axisY)
        .style('font-size', '16px')
      var ylab = svg.append('text')
        .attr('transform', 'translate(15,180) rotate(270)')
      ylab.selectAll('tspan')
        .data(["Intensity"])
        .enter()
        .append('tspan')
        .text(dd => dd)
        .style('font-size', "20px")
    }
  },
}